<template>
  <div class="contact-us">
    <img src="https://images.zzt.com.cn/ox/2022/04/25/header_bar.png">
    <div class="content">
      <form class="part left">
        <div class="form-item">
          <label for="name"><span>*</span>姓名</label>
          <input type="text" placeholder="请输入姓名" v-model.trim="form.name">
        </div>
        <div class="form-item">
          <label for="name"><span>*</span>联系电话</label>
          <input type="text" placeholder="请输入手机号" v-model.trim="form.phone" maxlength="11">
        </div>
        <div class="form-item">
          <label for="name"><span>*</span>邮箱</label>
          <input type="text" placeholder="请输入邮箱" v-model.trim="form.email">
        </div>
        <div class="form-item">
          <label for="name"><span>*</span>公司名称</label>
          <input type="text" placeholder="请输入公司名称" v-model.trim="form.corpName">
        </div>
        <div class="form-item">
          <label for="name"><span>*</span>咨询内容</label>
          <textarea placeholder="请输入咨询内容" rows="8" v-model="form.content"></textarea>
        </div>
        <div class="form-item">
          <label for="name"></label>
          <span class="submit-btn" @click="submit">提交</span>
        </div>
      </form>
      <div class="part right">
        <section>
          <div class="title">
            <img src="https://images.zzt.com.cn/ox/2022/04/25/zzst_icon1.png" />
            <span>产品咨询</span>
          </div>
          <div class="main">
            <img src="https://images.zzt.com.cn/ox/2022/04/26/concatc1.png" />
            <img src="https://images.zzt.com.cn/ox/2022/04/26/concatc2.png" />
          </div>
        </section>
        <section>
          <div class="title">
            <img src="https://images.zzt.com.cn/ox/2022/04/25/zzst_icon2.png" />
            <span>技术支援</span>
          </div>
          <p>热线：010-8210 1180</p>
          <p>邮箱：service@zzt.com.cn</p>
        </section>
      </div>
    </div>
    <div class="dialog" v-show="visible" @click="visible=false">
      <div class="dialog-content">{{msg}}</div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
export default {
  name: 'AboutUs',
  data () {
    return {
      form: {
        name: '',
        phone: '',
        email: '',
        corpName: '',
        content: ''
      },
      visible: false,
      msg: '请填写完整信息'
    }
  },
  methods: {
    submit () {
      // 判断form是否有字段为空
      for (const key in this.form) {
        if (!this.form[key]) {
          this.msg = '请填写完整信息！'
          this.visible = true
          return
        }
      }
      axios.post(`${process.env.VUE_APP_API_BASEURL}/consult/save`, this.form).then(({ data, success }) => {
        if (success) {
          this.msg = '提交成功！'
          this.visible = true
        }
      })
    }
  }
}
</script>
<style lang='scss' scoped>
.contact-us {
  width: $container-width;
  position: relative;
  top: 40px;
  margin: 0 auto 80px;
  background-color: #fff;
  padding: 150px 120px;
  box-sizing: border-box;
  img {
    width: 100%;
  }
  .content {
    display: flex;
    padding-top: 90px;
    .part {
      flex: 1;
      text-align: left;
    }
    form {
      border-right: 1px solid #E8E8E8;
      .form-item {
        display: flex;
        margin-bottom: 20px;
      }
      label {
        color: #333;
        font-size: 38px;
        display: inline-block;
        width: 220px;
        padding-top: 20px;
        span {
          color: #E94C3D;
        }
      }
      input, textarea {
        display: inline-block;
        width: 660px;
        height: 80px;
        border: 1px solid #E8E8E8;
        border-radius: 10px;
        padding-left: 30px;
        outline: none;
        overflow: auto;
        resize: none;
        &::placeholder {
          color: #999;
          font-size: 36px;
        }
      }
      textarea {
        height: 240px;
        padding-top: 30px;
      }
      .submit-btn {
        padding: 18px 80px;
        background-color: #4088EB;
        color: #fff;
        border-radius: 40px;
        line-height: 46px;
        cursor: pointer;
        &:hover {
          background-color: #337EE5;
        }
      }
    }
    .right {
      padding-left: 220px;
      .title {
        display: flex;
        align-items: center;
        color: #4088EB;
        font-size: 46px;
        padding-bottom: 30px;
        img {
          width: 60px;
          padding-right: 40px;
        }
      }
      .main {
        padding-bottom: 80px;
        display: flex;
        img {
          width: 280px;
          margin-right: 40px;
          padding: 20px;
          border: 1px solid #e5e6e9;
        }
      }
      p {
        margin: 0;
        line-height: 1.8;
      }
    }
  }
}

.dialog {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  &-content {
    width: 600px;
    height: 200px;
    line-height: 200px;
    border-radius: 20px;
    box-shadow: 16px 16px 20px #999;
    background-color: #fff;
    color: #333;
  }
}
</style>
